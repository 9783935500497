/* Overlay for the loader */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it stays on top of other elements */
}

/* Spinner animation */
.loader-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #ddd;
  border-top: 6px solid #3498db; /* Primary color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for the spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
