.crypto-container {
  // background: #07232E;
  // border-radius: 3px;
  // border: 1px solid rgba(255, 255, 255, 0.3);
  // box-shadow: 0px 1px 0px 1px rgb(141, 131, 131);
  display: flex;
  z-index: 9999;
  margin-left: 1%;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  //border: 1px solid rgb(134, 134, 134);
}
.crypto-menu {
  width: 100%;
  max-height: 585px;
  overflow-y: auto;
  overflow-x: hidden;
}

.crypto-button {
  color: rgb(196, 196, 196);
  background-color: rgb(76, 76, 76);
  // margin-top: 2px;
  // margin: 1px;
  font-size: 0.9rem;
  padding: 8px 6px;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  // text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
}
.crypto-button-active {
  font-weight: 500;
  font-size: 0.9rem;

  color: #a3c8e3;
  background-color: rgb(37, 37, 37);
  // margin-top: 2px;
  // margin: 0px;
  padding: 8px 6px;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
  // text-transform: uppercase;
}

.crypto-rows {
  //background: #131722;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 1px solid rgba(100, 100, 100, 0.507);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
}

.crypto-rows-active {
  //background: #131722;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 2px solid rgba(0, 255, 229, 0.983);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
  background-color: #3d3d3d62;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.stocks-rows {
  //background: #131722;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  height: 40px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 1px solid rgba(100, 100, 100, 0.507);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
}

.stocks-rows-active {
  //background: #131722;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 2px solid rgba(0, 255, 229, 0.983);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
  background-color: #3d3d3d62;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.crypto-buttons {
  display: flex;
  //background-color: gray;
  // margin: 0 6px;
  // padding: 4px;
  border: 1px solid rgba(100, 100, 100, 0.507);
}

.crypto-icon-name-wrap {
  display: flex;
  align-items: center;
  :first-child {
    padding-right: 8px;
    margin-top: 2px;
  }
}
.crypto-price-star-wrap {
  display: flex;
  align-items: center;
  :first-child {
    padding-right: 8px;
  }
}
.star-div {
  // background-color: rgb(228, 228, 228);
  display: flex;
  width: 25px;
  // align-items: flex-end;
  // justify-content: ;
  :first-child:hover {
    color: rgb(255, 196, 0);
  }
}

// .star-svg {
//   width: 100%;
//   // height: 30px;
// }

.crypto-name {
  width: 50%;
  text-align: left;
  color: rgb(196, 196, 196);
}

.crypto-dashes {
  position: relative;
  color: rgb(196, 196, 196);
}

.stocks-name {
  color: white;
  width: 50%;
  text-align: center;
  align-items: center;
}

.stocks-dashes {
  color: white;
  text-align: center;
}

.forex-rows {
  //background: #131722;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  height: 40px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 1px solid rgba(100, 100, 100, 0.507);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
}
.forex-rows-active {
  //background: #131722;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 2px solid rgba(0, 255, 229, 0.983);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
  background-color: #3d3d3d62;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.forex-name {
  color: white;
  width: 50%;
  align-items: center;
}

.forex-dashes {
  color: white;
  text-align: center;
}

.commodities-rows {
  //background: #131722;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  height: 40px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 1px solid rgba(100, 100, 100, 0.507);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
}

.commodities-rows-active {
  //background: #131722;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  // border-left: ;
  border-left: 2px solid rgba(0, 255, 229, 0.983);
  border-right: 1px solid rgba(100, 100, 100, 0.507);
  border-bottom: 1px solid rgba(100, 100, 100, 0.507);
  background-color: #3d3d3d62;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.commodities-name {
  color: white;
  width: 50%;
  align-items: center;
}

.commodities-dashes {
  color: white;
  text-align: center;
}
