.contact-us-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 3.5rem;
  width: 100%;
  align-items: center;
  background: url("../../../public/faqpage2.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-us-page-inner-container {
  background-color: #212121;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  background: #05161e;
  height: 800px;
  background: url("../../../public/ass2.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-us-page-left-side-content-container {
  .contact-us-page-left-side-content-mobile {
    display: flex;
    flex-direction: column;
    background: #151515;
    width: 90%;
    box-shadow: 0px -20px 60px -20px black;
    height: 28%;
    margin: 2% auto;
    text-align: center;
    color: white;

    border-radius: 3%;
    .contact-us-page-left-side-contact-info-inner-box {
      text-wrap: nowrap;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      padding: 0.3rem;
      text-align: left;
      margin: 1rem auto;

      width: 100%;
    }
    h3 {
      font-family: fantasy;
      font-weight: normal;
      color: #c9c6c6;
      font-size: 1.2rem;
      text-shadow: 0px 1px rgb(0, 0, 0);
      padding: 1rem;
    }
    p {
      font-size: 0.8rem;
      color: #1199fa;
      text-align: center;
      text-shadow: 0px 1px rgb(0, 0, 0);
      margin-left: 0.5rem;
    }
    span {
      font-size: 0.7rem;
    }
  }
  .contact-us-page-left-side-content {
    display: flex;
    flex-direction: column;
    background: #151515;
    width: 100%;
    box-shadow: 0px -20px 60px -20px black;
    height: 28%;
    margin: 2rem auto;
    text-align: center;
    color: white;
    margin-left: 15%;

    border-radius: 3%;
    .contact-us-page-left-side-contact-info-inner-box {
      text-wrap: nowrap;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      padding: 0.3rem;
      text-align: left;
      margin: 1rem auto;

      width: 100%;
    }
    h3 {
      font-family: fantasy;
      font-weight: normal;
      color: #c9c6c6;
      font-size: 1.7rem;
      text-shadow: 0px 1px rgb(0, 0, 0);
      padding: 1rem;
    }
    p {
      font-size: 1.1rem;
      color: #1199fa;
      text-align: center;
      text-shadow: 0px 1px rgb(0, 0, 0);
      margin-left: 0.5rem;
    }
  }
}
.contact-us-page-right-side-content {
  display: flex;
  flex-direction: column;
  width: 40%;
  background: #151515;
  box-shadow: 0px -20px 60px -20px black;
  height: 85%;
  margin: 4% auto;
  text-align: center;
  color: white;
  border-radius: 3%;
  h2 {
    margin: 1rem auto;
    font-family: fantasy;
    font-weight: normal;
    color: #c9c6c6;
    font-size: 2rem;
    text-shadow: 0px 1px rgb(0, 0, 0);
    padding: 1rem;
  }
  .contact-us-page-right-side-inputs-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 1rem auto;
  }
}
.contact-us-page-header {
  // font-family: "Press Start 2P", cursive;
  color: #648eb3 !important;
  font-size: 60px;
  left: 5%;
  margin-left: 40px;
  filter: blur(1px);
  font-weight: bolder;
  position: absolute;
}
.contact-us-page-textBox {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 35px;
  margin-top: 50px;
  position: relative;
  text-align: center;
}

.contact-us-page-h3 {
  color: white;
  text-shadow: 1px 1px 4px black;
}
.contact-us-page-span {
  font-size: 24px;
  // font-family: "Mali", cursive;
  color: #d2d2d2;
  margin-left: 25%;
  text-shadow: 1px 1px 4px black;

  width: 50%;
}
.container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

#contact {
  background: #f9f9f9;
  padding: 25px;
  margin: 150px 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #648eb3;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  background: #03857e;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}
// scss-lint:disable VendorPrefix, Indentation
$bg: #616161;
$text-light: #fafafa;
$text: #212121;
$placeholder: #999;
$panel-bg: #0a5f76;
$shadow-dark: rgba(0, 0, 0, 0.65);
$button: #648eb3;
$button-hover: #fff;
$button-active: #1199fa;

//MIXINS
@mixin transition($time) {
  -webkit-transition: all $time;
  transition: all $time;
}

a {
  @include transition(0.4s);
  color: $button-active;
  text-decoration: none;

  &:hover {
    color: $button-hover;
    //font-weight: bold;
  }
}

.contact-us-panel {
  @include transition(0.7s);
  height: 38rem;
  background: $panel-bg;
  border-radius: 5px;
  box-shadow: 0 5px 20px $shadow-dark;
  color: $text;
  left: 0;
  width: 600px;
  margin: 0 auto 150px;
  overflow: hidden;
  padding-top: 30px;
  position: relative;
  right: 0;
  top: 50px;
  text-align: center;

  button {
    @include transition(0.7s);
    background: $button;
    border: 0;
    border-radius: 6px;
    color: $text;
    cursor: pointer;
    display: block;
    font-size: 1.5em;
    font-weight: 300;
    margin: 20px auto;
    padding: 10px;
    width: 330px;

    &:active {
      background: $button-active;
      color: $text;
    }

    &:hover {
      @include transition(0.55s);
      background: $button-hover;
      box-shadow: 0 2px 8px $shadow-dark;
      color: $panel-bg;
      text-shadow: 0 0 1px $shadow-dark;
    }
  }

  &:hover {
    @include transition(0.7s);
    border-radius: 35px 35px 35px 5px;
    box-shadow: 0 2px 5px $shadow-dark;
  }
}

.contact-us-panel-group {
  margin: 20px auto;
  position: relative;
  width: 60%;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact-us-input {
  border: 0;
  border-bottom: 1px solid $text;
  display: block;
  font-size: 1em;
  padding: 10px 10px 10px 5px;

  &:focus {
    outline: 0;
  }
}

label {
  @include transition(0.2s);
  color: $placeholder;
  font-size: 14px;
  font-weight: normal;
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 10px;
}

//animate label when input is in focus
.contact-us-input:focus ~ label,
.contact-us-input:valid ~ label {
  color: $button-hover;
  font-size: 0.75em;
  font-weight: bold;
  top: -20px;
}

//animate bottom border when input is in focus*/
.contact-us-border {
  display: block;
  position: relative;
  width: 315px;
}

.contact-us-border::before,
.contact-us-border::after {
  @include transition(0.5s);
  background: $button-active;
  bottom: 1px;
  content: "";
  height: 2px;
  position: absolute;
  width: 0;
}

.contact-us-border::before {
  left: 50%;
}

.contact-us-border::after {
  right: 50%;
}

//border width when clicked*/
.contact-us-input:focus ~ .border::before,
.contact-us-input:focus ~ .border::after {
  width: 50%;
}

.contact-us-panel-footer {
  font-size: 1.2em;
}

footer {
  bottom: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.contact-us-follow {
  margin: 0 auto;
}

@media only screen and (max-width: 667px) {
  .contact-us-page-inner-container {
    flex-direction: column;
    margin-top: 10%;
    height: auto;
    padding-bottom: 10%;
  }
  .contact-us-page-right-side-content {
    width: 90%;
    padding-bottom: 5%;
  }
  .contact-us-page-left-side-content {
    margin: 0 auto;
  }
  .contact-us-page-header {
    font-size: 25px;
    text-align: center;
    margin: 50px 50px auto;
  }
  .contact-us-page-h3 {
    font-size: 30px;
    margin-bottom: 30px;
    margin-top: 70px;
  }

  .contact-us-page-span {
    margin: 0;
    width: 100%;
    font-size: 18px;
  }

  .contact-us-panel {
    margin: 0 auto;
    background: $panel-bg;
    border-radius: 2px;
    box-shadow: 0 5px 20px $shadow-dark;
    color: $text;
    width: 80%;
    text-align: center;
    margin-bottom: 20px;
    height: auto;
    margin-bottom: 50px;
    padding: 20px;

    .contact-us-input {
      border-bottom: 1px solid $text;
      font-size: 1em;
      width: 80%;
      height: 30%;
      margin-bottom: 40px;
    }

    textarea {
      height: 10px;
    }

    button {
      @include transition(0.7s);
      background: $button;
      border: 0;
      border-radius: 2px;
      color: $text;
      cursor: pointer;
      display: block;
      font-size: 16px;
      font-weight: 300;
      margin: 20px auto;
      padding: 10px;
      width: 60%;
    }

    &:hover {
      @include transition(0.7s);
      border-radius: 35px 35px 35px 5px;
      box-shadow: 0 2px 5px $shadow-dark;
    }

    .contact-us-panel-group {
      margin: 0 auto;
    }
  }
}
